<template>
  <v-main :class="desktop ? 'tway-background-curves-svg' : 'tway-background-curves-svg-mobile'">
    <v-container fill-height :style="!desktop ? 'padding-top: 120px !important' : ''">
      <v-row class="ma-0" justify="center">
        <v-card
          outlined
          :width="desktop ? '70%' : '100%'"
          :class="desktop ? 'px-12' : 'pa-4'"
          style="border-radius: 10px !important"
        >
          <v-form>
            <grid
              :class-left="
                (desktop ? 'registration-form-border-right' : '') + ' pa-5 py-md-6 px-md-8 py-lg-10 px-lg-14'
              "
              class-right="pa-5 py-md-6 px-md-8 py-lg-14 px-lg-14"
              align-row="center"
            >
              <template v-slot:alone>
                <v-row class="pa-0 ma-0" :class="desktop ? 'pa-14' : 'pa-4'" justify="center">
                  <span class="px-2 text-h5 text-md-h5 mb-4 text-center">
                    ¡Genial! Te enviamos un correo para completar la activación de tu cuenta. Revisa tu casilla y sigue
                    las instrucciones para validar tu registro.
                  </span>

                  <v-row class="ma-0 pa-0">
                    <v-col cols="12" class="ma-0 pa-0">
                      <v-row class="ma-0 pa-0" justify="center">
                        <v-img
                          :src="require('@/assets/img/email sent.svg')"
                          class="rounded mb-8"
                          contain
                          max-width="50%"
                        />
                      </v-row>
                    </v-col>
                    <v-col cols="12">
                      <v-row class="ma-0 pa-0" justify="center">
                        <span>¿No recibes el correo aún?</span>
                      </v-row>
                    </v-col>
                    <v-col cols="12" class="ma-0 pa-0 mb-8 mt-8">
                      <v-row class="ma-0 pa-0" justify="center">
                        <v-btn
                          depressed
                          rounded
                          :disabled="false"
                          class="button-text white--text"
                          color="tway-violet"
                          id="register-ready-email"
                        >
                          <span class="px-8">reenviar</span>
                        </v-btn>
                      </v-row>
                    </v-col>
                    <v-col cols="12" class="ma-0 pa-0">
                      <v-row class="ma-0 pa-0" justify="center">
                        <router-link class="tway-violet--text" :to="{ name: 'login' }"> Volver al inicio </router-link>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-alert class="mt-4" outlined type="info" text color="#F7C938" style="max-width: 100%">
                      <span style="color: #666666">
                        Los datos que acabas de registrar serán verificados para conocer si el RUT asociado ya se
                        encuentra registrado por un usuario administrador.
                      </span>
                    </v-alert>
                  </v-row>
                </v-row>
              </template>
            </grid>
          </v-form>
        </v-card>
      </v-row>
    </v-container>
  </v-main>
</template>

<style scoped lang="scss">
.thanks-container {
  display: flex;
  justify-content: center;
}
.thanks-card {
  max-width: 800px;
}
</style>

<script>
/* eslint-disable */
import RowTwo from "@/components/grid/RowTwo.vue";
// import Modal from '@/components/elements/Modal.vue';

export default {
  components: {
    grid: RowTwo
    // 'modal': Modal
  },
  data() {
    return {
      modal: false,
      email: ""
    };
  },
  methods: {
    resend() {
      this.modal = true;
      return false;
    }
  },
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs;
    },
    desktop() {
      return (
        this.$vuetify.breakpoint.md ||
        this.$vuetify.breakpoint.lg ||
        this.$vuetify.breakpoint.xl
      );
    }
  }
};
</script>

<style scoped>
.width-container {
  max-width: 600px;
}
</style>
